import React, {Component} from 'react';
import ApplicationContext from '../ApplicationContext';
import {Redirect} from 'react-router-dom';
import API from '../API';

class PlaceManagementPage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateAlert = props.updateAlert;
    }

    componentDidMount() {
        let {updateNavBarWith} = this.props;
        if (this.context.place) {
            updateNavBarWith(this.context.place.name);
        }
        this.updateAlert(null);
    }

    handleSubmit(event) {
        event.preventDefault();
        let instance = this;
        API.post(process.env.REACT_APP_API_URL + '/report', {
            placeGoogleId: instance.context.place.googleId,
            userForecast: event.target.peopleWaitingInput.value,
            verified: true
        }, {
            headers: {
                'uuid': 'web-' + instance.context.place.googleId,
                'platform': 'business'
            }
        })
            .then(function (response) {
                if (response.data.status === 'ok') {
                    document.getElementById('peopleWaitingForm').reset();
                    instance.updateAlert('Merci pour votre contribution !', false);
                } else {
                    instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const message = error.response.data.message;
                    if (message === 'report_negativeNumber') {
                        instance.updateAlert('Insérez un nombre positif.');
                    } else if (message === 'report_tooHigh') {
                        instance.updateAlert('Cette valeur est trop élevée.');
                    } else {
                        instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                    }
                } else {
                    instance.updateAlert('Votre appareil semble déconnecté d\'Internet. Réessayez.');
                }
            });
    }

    render() {
        if (!this.context.place) {
            return <Redirect to='/login'/>
        } else {
            return (
                <form id="peopleWaitingForm" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label className="mt-4" htmlFor="peopleWaitingInput">J'ai compté environ...</label>
                        <input type="number" className="form-control" id="peopleWaitingInput" min="0" required/>
                        <p className="mt-2">...personnes attendant actuellement devant mon commerce.</p>
                    </div>
                    <button type="submit" className="btn btn-primary">Envoyer</button>
                    <p className="mt-4"><small>Cette donnée sera considérée comme véridique. Merci de rester réaliste.</small></p>
                </form>
            );
        }
    }
}

PlaceManagementPage.contextType = ApplicationContext;
export default PlaceManagementPage;
