import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import API from '../API';
import ApplicationContext from '../ApplicationContext';
import PromotionDownload from '../components/PromotionDownload';

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateAlert = props.updateAlert;
        this.state = {loggedIn: false};
    }

    componentDidMount() {
        let {updateNavBarWith} = this.props;
        updateNavBarWith('Connexion');
        this.updateAlert(null);
    }

    handleSubmit(event) {
        event.preventDefault();
        let instance = this;
        API.post(process.env.REACT_APP_API_URL + '/login', {
            email: event.target.email.value,
            password: event.target.password.value
        })
            .then(function (response) {
                if (response.data.status === 'ok') {
                    instance.context.place = response.data.data;
                    instance.setState({loggedIn: true});
                } else {
                    instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                }
            })
            .catch(function (error) {
                if (error.response) {
                    switch (error.response.data.message) {
                        case 'user.unknown':
                            instance.updateAlert('Cet utilisateur n\'existe pas ou le mot de passe est incorrect.');
                            break;
                        case 'user.waitingApproval':
                            instance.updateAlert('Quel enthousiasme ! Il vous faudra néanmoins attendre que nous prenions contact avec vous par email pour activer votre compte.');
                            break;
                        default:
                            instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                            break;
                    }
                } else {
                    instance.updateAlert('Votre appareil semble déconnecté d\'Internet. Réessayez.');
                }
            });
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect to='/'/>
        } else {
            return (
                <>
                    <h4 className="mb-4">Contribuez aux données de votre propre commerce !</h4>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" className="form-control" id="password" required/>
                        </div>
                        <button type="submit" className="btn btn-primary">Se connecter</button>
                        <Link to={"/register"} className="ml-2 btn btn-outline-primary">Inscription</Link>
                    </form>
                    <PromotionDownload />
                </>
            );
        }
    }
}

LoginPage.contextType = ApplicationContext;
export default LoginPage;
