import Axios from 'axios'
import axiosCookieJarSupport from 'axios-cookiejar-support';
import tough from 'tough-cookie';

const API = Axios.create({
    withCredentials: true
})
axiosCookieJarSupport(API);
API.defaults.jar = new tough.CookieJar();

export default API;
