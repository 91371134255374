import React, {Component} from 'react';
import ApplicationContext from '../ApplicationContext';

class PromotionDownload extends Component {

    render() {
        return (
            <>
                <p className="text-middle mt-5 mb-2">Pensez à promouvoir l'application en la partageant sur votre devanture ainsi que sur les réseaux sociaux !</p>
                <a href="https://smartq-app.ch/download/promo-fr.zip">
                    <svg className="bi bi-arrow-down-circle-fill align-text-bottom" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 5a.5.5 0 0 0-1 0v4.793L5.354 7.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 9.793V5z"/>
                    </svg>
                &nbsp;Télécharger les documents de promotion
                </a>
            </>
        );
    }

}

PromotionDownload.contextType = ApplicationContext;
export default PromotionDownload;