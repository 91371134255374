import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';

import './App.scss';
import './App.css';

import LoginPage from './pages/LoginPage';
import PlaceManagementPage from './pages/PlaceManagementPage';
import ApplicationContext from './ApplicationContext';
import API from './API';
import RegisterPage from "./pages/RegisterPage";

class App extends Component {

    constructor(props) {
        super(props);
        this.updateNavBarWith = this.updateNavBarWith.bind(this);
        this.onClickDisconnect = this.onClickDisconnect.bind(this);
        this.updateAlert = this.updateAlert.bind(this);
        this.state = {title: 'Connexion', checkedLogin: false, alert: null};
    }

    onClickDisconnect() {
        let instance = this;
        API.get(process.env.REACT_APP_API_URL + '/logout')
            .then(function (response) {
                instance.context.place = null;
                instance.setState({title: 'Connexion'});
            })
            .catch(function (error) {
            });
    }

    updateNavBarWith(title) {
        this.setState({title: title});
    }

    // `content` = null to hide
    // `error` = false for success
    updateAlert(content, error = true) {
        if (content === null) {
            this.setState({alert: null});
        } else {
            this.setState({alert: {content: content, error: error}});
        }
    }

    componentDidMount() {
        let instance = this;
        API.post(process.env.REACT_APP_API_URL + '/login')
            .then(function (response) {
                if (response.data.status === 'ok') {
                    instance.context.place = response.data.data;
                }
                instance.setState({checkedLogin: true});
            })
            .catch(function (error) {
                instance.setState({checkedLogin: true});
            });
    }

    render() {
        let disconnectButton;
        if (this.context.place && this.context.place.googleId) {
            disconnectButton = <button type="button" className="btn btn-link ml-auto my-2 my-sm-0" onClick={this.onClickDisconnect} title="Déconnexion">Déconnexion</button>;
        } else {
            disconnectButton = null;
        }

        let alert;
        if (this.state.alert !== null) {
            const alertClass = this.state.alert.error ? 'alert-danger' : 'alert-success'
            alert = <div className={'alert ' + alertClass} role="alert">
                        {this.state.alert.content}
                        <button type="button" className="close" aria-label="Close" onClick={() => this.updateAlert(null)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>;
        } else {
            alert = null;
        }

        if (!this.state.checkedLogin) {
            return <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>;
        } else {

            return (
                <Router>
                    <main role="main" className="flex-shrink-0">
                        <nav className="navbar navbar-light bg-light">
                            <Link to={"/"} className="navbar-brand">
                                <img className="mr-2 d-inline-block align-top" width="30" height="30" loading="lazy" alt="Logo" src={process.env.PUBLIC_URL + '/logo192.png'} />
                                SmartQ
                            </Link>
                            <span className="navbar-text d-none d-md-block mr-auto">{this.state.title}</span>
                            {disconnectButton}
                            <span className="navbar-text d-block d-md-none container-fluid pl-0">{this.state.title}</span>
                        </nav>

                        <div className="container my-4">
                            {alert}

                            <Switch>
                                <Route path="/login" onEnter={() => this.setState({title: 'Connexion'})}>
                                    <LoginPage updateNavBarWith={this.updateNavBarWith} updateAlert={this.updateAlert}/>
                                </Route>
                                <Route path="/register" onEnter={() => this.setState({title: 'Inscription'})}>
                                    <RegisterPage updateNavBarWith={this.updateNavBarWith} updateAlert={this.updateAlert}/>
                                </Route>
                                <Route path="/" onEnter={() => this.setState({title: 'Mon magasin'})}>
                                    <PlaceManagementPage updateNavBarWith={this.updateNavBarWith} updateAlert={this.updateAlert}/>
                                </Route>
                            </Switch>
                        </div>
                    </main>
                    <footer className='footer mt-auto px-2 pt-5 pb-2 text-center text-muted'>
                        <small>
                            <p>Un problème ? Contactez-nous en <a href="mailto:contact@smartq-app.ch">cliquant ici</a>.</p>
                            <p>© {new Date().getFullYear()} <a href="https://smartq-app.ch">SmartQ</a></p>
                        </small>
                    </footer>
                </Router>
            );
        }
    }
}

App.contextType = ApplicationContext;
export default App;
