import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import API from '../API';
import ApplicationContext from '../ApplicationContext';
import PromotionDownload from '../components/PromotionDownload';

class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateAlert = props.updateAlert;
        this.state = {loggedIn: false};
    }

    componentDidMount() {
        let {updateNavBarWith} = this.props;
        updateNavBarWith('Inscription');
        this.updateAlert(null);
    }

    handleSubmit(event) {
        event.preventDefault();
        let instance = this;
        if (event.target.password.value !== event.target.repassword.value) {
            instance.updateAlert('Les mots de passe ne correspondent pas.');
        } else {
            API.post(process.env.REACT_APP_API_URL + '/register', {
                email: event.target.email.value,
                password: event.target.password.value,
                name: event.target.name.value,
                address: event.target.address.value
            })
                .then(function (response) {
                    if (response.data.status === 'ok') {
                        instance.updateAlert('Un grand merci pour votre intérêt ! Votre demande a bien été prise en compte, nous vous contacterons par email dans les meilleurs délais.', false);
                        instance.setState({registered: true});
                    } else {
                        instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.data.message === 'user.exists') {
                            instance.updateAlert('Cet utilisateur existe déjà. Si vous avez plusieurs commerces, utilisez une adresse email différente pour chacun d\'entre eux.');
                        } else if (error.response.data.message === 'email.invalid') {
                            instance.updateAlert('Email non valide.');
                        } else {
                            instance.updateAlert('Une erreur s\'est produite. Réessayez.');
                        }
                    } else {
                        instance.updateAlert('Votre appareil semble déconnecté d\'Internet. Réessayez.');
                    }
                });
        }
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect to='/'/>
        } else if (this.state.registered) {
            return <><PromotionDownload /></>;
        } else {
            return (
                <>
                    <h4 className="mb-4">Inscrivez-vous pour contribuer aux données de votre propre commerce !</h4>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Nom de votre commerce</label>
                            <input type="name" className="form-control" id="name" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Adresse de votre commerce</label>
                            <input type="address" className="form-control" id="address" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                   required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" className="form-control" id="password" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="repassword">Retaper le mot de passe</label>
                            <input type="password" className="form-control" id="repassword" required/>
                        </div>
                        <button type="submit" className="btn btn-primary">S'inscrire</button>
                    </form>
                    <PromotionDownload />
                </>
            );
        }
    }
}

RegisterPage.contextType = ApplicationContext;
export default RegisterPage;
